import React from 'react';
import { routes } from '../../constants/routes';
import { Link } from 'react-router-dom';
import { Button } from '@components/button';
import { LogOutIcon } from 'lucide-react';
import { useUserContext } from '@hooks/context/user_context';

const Sidebar = () => {
    const {handleLogout} = useUserContext()
    return (
        <div className="flex flex-col w-64 gap-6 pt-4 justify-between h-[100vh] border-r sticky top-0">
            <div>
                <div className="p-4 text-2xl font-bold">
                    Grand Combat
                </div>
                <div className="flex flex-col w-full">
                    {routes.map((route) => <Link to={route.link} key={route.link}
                                                 className="flex items-center gap-2 w-full p-4 hover:bg-[rgba(255,255,255,0.2)] transition">
                        <route.Icon />
                        {route.label}
                    </Link>)}
                </div>
            </div>
            <Button variant="destructive" className="gap-2" onClick={handleLogout}>
                <LogOutIcon /> Logout
            </Button>
        </div>
    );
};

export default Sidebar;
