import React, { FC, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger
} from '@components/dialog';
import { Button } from '@components/button';
import { Card, CardDescription, CardTitle } from '@components/card';
import { EditIcon } from 'lucide-react';
import { EIssueType, TIssue } from '@types';
import { SelectIssueStatus } from '../complete-ui/select-issue-type';
import { useDebounceValue } from 'usehooks-ts';
import { toast } from '@components/use-toast';

import { useApiMutation } from '@hooks/api/useApiMutation';
import { apiUpdateIssue } from '@api/dashboard';

interface IProps {
    issue: TIssue;
    refetch: () => void;
}

const UserModal: FC<IProps> = ({ issue, refetch }) => {
    const [statusValue, setStatusValue] = useDebounceValue<EIssueType | undefined>(undefined, 0);

    const { handleRequest } = useApiMutation({
        method: apiUpdateIssue,
        onSuccess: () => {
            toast({
                variant: 'default',
                description: `Issue has been successfully updated`
            });

            refetch();
        },
        onError: () => {
            toast({
                variant: 'destructive',
                description: `Something went wrong`
            });
        }
    });

    console.log(statusValue);

    useEffect(() => {
        if (statusValue)
            handleRequest({
                id: issue.id,
                status: statusValue
            });
    }, [statusValue]);
    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button variant="ghost">
                    <EditIcon />
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader>
                    <DialogTitle>Issue ({issue.id})</DialogTitle>
                </DialogHeader>
                <div className="flex flex-col gap-3">
                    <div className="flex gap-2 items-center">{issue.username}</div>

                    <div className="flex gap-2 flex-wrap flex-col">
                        <div className="flex flex-row gap-2">
                            <Card className="w-max p-3 flex flex-col gap-1">
                                <CardDescription>Is Premium</CardDescription>
                                <CardTitle
                                    className={issue.isPremium ? 'text-green-600' : 'text-red-600'}
                                >
                                    {issue.isPremium ? 'Yes' : 'No'}
                                </CardTitle>
                            </Card>

                            <Card className="w-max p-3 flex flex-col gap-1">
                                <CardDescription>Status</CardDescription>
                                {/* <CardTitle>{issue.status}</CardTitle> */}
                                <SelectIssueStatus
                                    defaultValue={issue.status}
                                    onChange={setStatusValue}
                                />
                            </Card>

                            <Card className="w-max p-3 flex flex-col gap-1">
                                <CardDescription>Type</CardDescription>
                                <CardTitle>{issue.type}</CardTitle>
                            </Card>
                        </div>

                        <Card className="p-3 flex flex-col gap-1">
                            <CardDescription>Message</CardDescription>

                            <CardTitle className="text-wrap whitespace-pre-wrap break-words">
                                {issue?.message}
                            </CardTitle>
                        </Card>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default UserModal;
