import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue
} from '@components/select';
import { EIssueType } from '@types';
import { FC } from 'react';

interface IProps {
    onChange: (v: EIssueType) => void;
    defaultValue?: string;
}

export const SelectIssueStatus: FC<IProps> = ({ defaultValue, onChange }) => {
    return (
        <Select onValueChange={onChange} defaultValue={defaultValue}>
            <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Select a status" />
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    <SelectLabel></SelectLabel>

                    <SelectItem value={EIssueType.New}>{EIssueType.New?.toUpperCase()}</SelectItem>

                    <SelectItem value={EIssueType.Processing}>
                        {EIssueType.Processing?.toUpperCase()}
                    </SelectItem>

                    <SelectItem value={EIssueType.Deferred}>
                        {EIssueType.Deferred?.toUpperCase()}
                    </SelectItem>

                    <SelectItem value={EIssueType.Cancelled}>
                        {EIssueType.Cancelled?.toUpperCase()}
                    </SelectItem>

                    <SelectItem value={EIssueType.Resolved}>
                        {EIssueType.Resolved?.toUpperCase()}
                    </SelectItem>
                </SelectGroup>
            </SelectContent>
        </Select>
    );
};
