import React from 'react';
import Sidebar from '@sections/sidebar';
import { Outlet } from 'react-router-dom';

const Layout = () => {
    return (
        <div className="flex min-h-[100vh]">
            <Sidebar />
            <div className="p-8 w-[calc(100%-256px)]">
                <Outlet />
            </div>
        </div>
    );
};

export default Layout;
