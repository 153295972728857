export enum ERoutes {
    HOME = '/',
    LOGIN = '/login'
}

export enum ECardType {
    MARKET = 'market',
    TEAM = 'team',
    LEGAL = 'legal',
    SPECIAL = 'specials'
}

export enum ETaskType {
    LEVEL = 'level',
    REFERRALS = 'referrals',
    NEW_REFERRALS = 'new_referrals',
    CARD = 'card'
}
