import { ERoutes } from '../typescript/enums';
import { BugIcon } from 'lucide-react';

export const routes = [
    {
        label: 'Issues',
        Icon: BugIcon,
        link: ERoutes.HOME
    }
];
