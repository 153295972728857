import React, { useState } from 'react';
import { Card, CardDescription, CardHeader, CardTitle } from '@components/card';
import { prettifyNumber } from '@lib/utils';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@components/table';
import {
    Pagination,
    PaginationContent,
    PaginationItem,
    PaginationLink,
    PaginationNext,
    PaginationPrevious
} from '@components/pagination';
import { Avatar, AvatarFallback, AvatarImage } from '@components/avatar';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue
} from '@components/select';
import { Input } from '@components/input';
import { useDebounceValue } from 'usehooks-ts';
import UserModal from '../components/modals/user';
import { ArrowUp } from 'lucide-react';
import { Button } from '@components/button';
import { apiGetIssuesList, apiGetIssuesStats } from '@api/dashboard';
import { EConversationType, EIssueType } from '@types';
import { SelectIssueStatus } from '../components/complete-ui/select-issue-type';

const Home = () => {
    const [pagination, setPagination] = useState<{ page: string; pageSize: string; sort: string }>({
        page: '0',
        pageSize: '100',
        sort: 'createdAt'
    });

    const [searchValue, setSearchValue] = useDebounceValue('', 500);
    const [typeValue, setTypeValue] = useDebounceValue<string | undefined>(undefined, 0);
    const [statusValue, setStatusValue] = useDebounceValue<string | undefined>(undefined, 0);

    const { data } = useApiQuery({
        method: apiGetIssuesStats,
        isInitialRequest: true,
        isInterval: true
    });

    const { data: usersListData, handleRequest: refetch } = useApiQuery({
        method: apiGetIssuesList,
        isInitialRequest: true,
        isInterval: true,
        data: {
            ...pagination,
            username: searchValue,

            type: typeValue,
            status: statusValue
        },
        deps: [pagination, searchValue, typeValue, statusValue]
    });

    const handlePagePrev = () => {
        if (usersListData) {
            setPagination((state) => ({
                ...state,
                page: Number(state.page) > 0 ? String(Number(state.page) - 1) : state.page
            }));
        }
    };

    const handlePageNext = () => {
        if (usersListData) {
            const { totalPages } = usersListData.metadata;
            setPagination((state) => ({
                ...state,
                page:
                    Number(state.page) === totalPages - 1
                        ? state.page
                        : String(Number(state.page) + 1)
            }));
        }
    };

    const handleChangeSort = (sort: string) => {
        if (pagination.sort.includes(sort)) {
            if (pagination.sort.includes('-')) {
                setPagination((state) => ({
                    ...state,
                    sort: sort
                }));
            } else {
                setPagination((state) => ({
                    ...state,
                    sort: `-${sort}`
                }));
            }
        } else {
            setPagination((state) => ({
                ...state,
                sort: sort
            }));
        }
    };

    return (
        <div className="flex flex-col gap-8 w-full">
            <h2 className="font-bold text-2xl">Dashboard</h2>
            <div className="flex items-center gap-6">
                <Card className="w-max">
                    <CardHeader>
                        <CardDescription>Today</CardDescription>
                        <CardTitle>{prettifyNumber(data?.currentDayUsers || 0)}</CardTitle>
                    </CardHeader>
                </Card>
                <Card className="w-max">
                    <CardHeader>
                        <CardDescription>Yesterday</CardDescription>
                        <CardTitle>{prettifyNumber(data?.previousDayUsers || 0)}</CardTitle>
                    </CardHeader>
                </Card>
                <Card className="w-max">
                    <CardHeader>
                        <CardDescription>Current Month</CardDescription>
                        <CardTitle>{prettifyNumber(data?.currentMonthUsers || 0)}</CardTitle>
                    </CardHeader>
                </Card>
                <Card className="w-max">
                    <CardHeader>
                        <CardDescription>Previous Month</CardDescription>
                        <CardTitle>{prettifyNumber(data?.previousMonthUsers || 0)}</CardTitle>
                    </CardHeader>
                </Card>
                <Card className="w-max">
                    <CardHeader>
                        <CardDescription>Total</CardDescription>
                        <CardTitle>{prettifyNumber(data?.totalCountUsers || 0)}</CardTitle>
                    </CardHeader>
                </Card>
            </div>
            <h2 className="font-bold text-2xl">Issues</h2>
            <div className="flex flex-col items-start gap-10 ">
                <div className="flex flex-row items-start gap-4">
                    <Input
                        placeholder="Filter usernames..."
                        className="max-w-sm self-start"
                        onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <SelectIssueStatus onChange={setStatusValue} />

                    <Select onValueChange={(v) => setTypeValue(v)}>
                        <SelectTrigger className="w-[180px]">
                            <SelectValue placeholder="Select a type" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                <SelectLabel></SelectLabel>

                                <SelectItem value={EConversationType.Bug}>
                                    {EConversationType.Bug?.toUpperCase()}
                                </SelectItem>

                                <SelectItem value={EConversationType.Other}>
                                    {EConversationType.Other?.toUpperCase()}
                                </SelectItem>

                                <SelectItem value={EConversationType.Question}>
                                    {EConversationType.Question?.toUpperCase()}
                                </SelectItem>

                                <SelectItem value={EConversationType.Visual}>
                                    {EConversationType.Visual?.toUpperCase()}
                                </SelectItem>
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>

                <Table className="w-full">
                    <TableHeader>
                        <TableRow>
                            <TableHead className="w-[100px]">Index</TableHead>
                            <TableHead>Username</TableHead>
                            <TableHead>
                                <Button
                                    onClick={() => handleChangeSort('type')}
                                    variant="ghost"
                                    className="w-full flex items-center gap-2 cursor-pointer"
                                >
                                    Type
                                    <ArrowUp
                                        className={`ml-2 h-4 w-4 ${pagination.sort === 'type' && 'rotate-180'}`}
                                    />
                                </Button>
                            </TableHead>
                            <TableHead className="text-center">
                                <Button
                                    onClick={() => handleChangeSort('status')}
                                    variant="ghost"
                                    className="w-full flex items-center gap-2 cursor-pointer"
                                >
                                    Status
                                    <ArrowUp
                                        className={`ml-2 h-4 w-4 ${pagination.sort === 'status' && 'rotate-180'}`}
                                    />
                                </Button>
                            </TableHead>
                            <TableHead className="text-center">
                                <Button
                                    onClick={() => handleChangeSort('languageCode')}
                                    variant="ghost"
                                    className="w-full flex items-center gap-2 cursor-pointer"
                                >
                                    Language Code
                                    <ArrowUp
                                        className={`ml-2 h-4 w-4 ${pagination.sort === 'languageCode' && 'rotate-180'}`}
                                    />
                                </Button>
                            </TableHead>
                            <TableHead className="text-center">
                                <Button
                                    onClick={() => handleChangeSort('isPremium')}
                                    variant="ghost"
                                    className="w-full flex items-center gap-2 cursor-pointer"
                                >
                                    Is Premium
                                    <ArrowUp
                                        className={`ml-2 h-4 w-4 ${pagination.sort === 'isPremium' && 'rotate-180'}`}
                                    />
                                </Button>
                            </TableHead>
                            <TableHead className="text-center">Actions</TableHead>
                        </TableRow>
                    </TableHeader>

                    {usersListData && (
                        <TableBody>
                            {usersListData?.data.issues.map((issue, index) => {
                                return (
                                    <TableRow key={issue._id}>
                                        <TableCell className="font-medium">
                                            {Number(pagination.page) * Number(pagination.pageSize) +
                                                index +
                                                1}
                                        </TableCell>
                                        <TableCell className="flex gap-2 items-center">
                                            {issue.username}
                                        </TableCell>

                                        <TableCell className="text-center">
                                            {issue?.type && issue?.type?.toUpperCase()}
                                        </TableCell>

                                        <TableCell className="text-center">
                                            {issue?.status && issue?.status?.toUpperCase()}
                                        </TableCell>
                                        <TableCell className="text-center">
                                            {issue?.languageCode &&
                                                issue?.languageCode?.toUpperCase()}
                                        </TableCell>

                                        <TableCell className="text-center flex content-center justify-center">
                                            <Card className="w-max p-3 flex flex-col gap-1 content-center justify-center">
                                                <CardTitle
                                                    className={
                                                        issue.isPremium
                                                            ? 'text-green-600 self-center '
                                                            : 'text-red-600'
                                                    }
                                                >
                                                    {issue.isPremium ? 'Yes' : 'No'}
                                                </CardTitle>
                                            </Card>
                                        </TableCell>

                                        <TableCell className="text-center">
                                            {issue && <UserModal refetch={refetch} issue={issue} />}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    )}
                </Table>

                <Pagination className="w-max m-0">
                    <PaginationContent>
                        <PaginationItem>
                            <PaginationPrevious
                                className="cursor-pointer"
                                onClick={handlePagePrev}
                            />
                        </PaginationItem>
                        <PaginationItem>
                            <PaginationLink
                                className="cursor-pointer"
                                onClick={() =>
                                    setPagination((state) => ({
                                        ...state,
                                        page: '0'
                                    }))
                                }
                            >
                                First
                            </PaginationLink>
                        </PaginationItem>
                        {Array(usersListData?.metadata?.totalPages)
                            .fill('')
                            .map((_, i) => {
                                if (
                                    i - 3 < Number(pagination.page) &&
                                    i + 3 > Number(pagination.page)
                                ) {
                                    return (
                                        <PaginationItem key={i}>
                                            <PaginationLink
                                                className="cursor-pointer"
                                                onClick={() =>
                                                    setPagination((state) => ({
                                                        ...state,
                                                        page: i.toString()
                                                    }))
                                                }
                                                isActive={pagination.page === i.toString()}
                                            >
                                                {i + 1}
                                            </PaginationLink>
                                        </PaginationItem>
                                    );
                                }
                                return null;
                            })}
                        <PaginationItem>
                            {usersListData && (
                                <PaginationLink
                                    className="cursor-pointer"
                                    onClick={() =>
                                        setPagination((state) => ({
                                            ...state,
                                            page: String(usersListData.metadata.totalPages - 1)
                                        }))
                                    }
                                >
                                    Last
                                </PaginationLink>
                            )}
                        </PaginationItem>
                        <PaginationItem>
                            <PaginationNext className="cursor-pointer" onClick={handlePageNext} />
                        </PaginationItem>
                    </PaginationContent>
                    <Select
                        value={pagination.pageSize}
                        onValueChange={(val) =>
                            setPagination((state) => ({ ...state, pageSize: val }))
                        }
                    >
                        <SelectTrigger className="w-[180px]">
                            <SelectValue placeholder="Page size" defaultValue={50} />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                <SelectItem value="100">100</SelectItem>
                                <SelectItem value="200">200</SelectItem>
                                <SelectItem value="500">500</SelectItem>
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </Pagination>
            </div>
        </div>
    );
};

export default Home;
