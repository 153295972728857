import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import { ERoutes } from './typescript/enums';
import Home from '@pages/home';
import Login from '@pages/login';
import Layout from './ui/layout';
import { UserContextProvider } from '@hooks/context/user_context';

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<UserContextProvider />}>
            <Route element={<Layout />}>
                <Route path={ERoutes.HOME} element={<Home />} />
            </Route>

            <Route path={ERoutes.LOGIN} element={<Login />} />
        </Route>
    )
);
