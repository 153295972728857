import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from './router';
import { ToastProvider } from '@components/toast';
import { Toaster } from '@components/toaster';
import axios from 'axios';

axios.defaults.headers.common['Authorization'] = localStorage.getItem('access')
    ? `Bearer ${localStorage.getItem('access')}`
    : undefined;
axios.defaults.baseURL = process.env.REACT_APP_BACKEND_API;
axios.defaults.headers.post['Content-Type'] = 'application/json';

function App() {
    return (
        <div>
            <ToastProvider>
                <RouterProvider router={router} />
                <Toaster />
            </ToastProvider>
        </div>
    );
}

export default App;
