import { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

interface IProps<T, R> {
    method: (data: any) => Promise<AxiosResponse<R>>;
    data?: T;
    isInitialRequest?: boolean;
    deps?: any[];
    onSuccess?: (data: R) => void;
    onError?: (error: any) => void;
    onFinish?: () => void;
    condition?: boolean;
    isInterval?: boolean;
    interval?: number;
}

export const useApiQuery = <T, R>({
    method,
    deps = [],
    isInitialRequest: isInitial = false,
    isInterval = false,
    interval = 60000,
    data: requestData,
    condition = true,
    onError,
    onSuccess,
    onFinish
}: IProps<T, R>) => {
    const [isInitialRequest, setIsInitialRequest] = useState(isInitial);
    const [response, setResponse] = useState<R | undefined>(undefined);
    const [error, setError] = useState<string>();
    const [isLoading, setLoading] = useState(false);

    console.log({ requestData });

    const handleRequest = async () => {
        setLoading(true);
        setError(undefined);
        method(requestData)
            .then(({ data }) => {
                setResponse(data);
                if (onSuccess) onSuccess(data);
            })
            .catch((error) => {
                setError(error.message);
                if (onError) onError(error);
            })
            .finally(() => {
                setLoading(false);
                if (onFinish) onFinish();
            });
    };

    useEffect(() => {
        let intervalId: NodeJS.Timeout | null = null;
        if (isInitialRequest) {
            if (condition) {
                if (isInterval) {
                    handleRequest();
                    intervalId = setInterval(() => {
                        handleRequest();
                    }, interval);
                } else {
                    handleRequest();
                }
            }
        }

        setIsInitialRequest(true);

        return () => {
            if (!!intervalId) {
                clearInterval(intervalId);
            }
        };
    }, deps);

    return {
        data: response,
        error,
        isLoading,
        handleRequest
    };
};
