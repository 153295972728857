import axios, { AxiosResponse } from 'axios';
import { IGetUsersListRequest } from '../typescript/requests';
import { IGetIssuesListResponse, IGetIssuesStatsResponse } from '../typescript/responses';
import { EIssueType, TIssue } from '@types';

export const apiGetIssuesStats = (): Promise<AxiosResponse<IGetIssuesStatsResponse>> => {
    return axios.get('/dashboard/get-stats');
};

export const apiGetIssuesList = ({
    page,
    pageSize,
    username,
    status,
    type,
    sort
}: IGetUsersListRequest): Promise<AxiosResponse<IGetIssuesListResponse>> => {
    return axios.post(
        `/dashboard/issues?page=${page}&pageSize=${pageSize}&sort={"${sort.replace('-', '')}":${sort.includes('-') ? -1 : 1}}`,
        {
            username: username || undefined,

            status: status || undefined,

            type: type || undefined
        }
    );
};

export const apiGetUserById = (id: number | string) => {
    return axios.get(`/dashboard/issue/${id}`);
};

export const apiUpdateIssue = ({
    id,
    status
}: {
    id: string;
    status: EIssueType;
}): Promise<AxiosResponse<TIssue>> => {
    return axios.put(`/issue/${id}`, { status });
};
