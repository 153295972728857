import { ECardType, ETaskType } from './enums';

export enum EConversationType {
    Visual = 'visual',
    Bug = 'bug',
    Question = 'question',
    Other = 'other'
}
export enum EIssueType {
    New = 'new',
    Processing = 'processing',
    Resolved = 'resolved',
    Cancelled = 'cancelled',
    Deferred = 'deffered'
}

export type TIssue = {
    _id: string;
    id: string;
    telegramId: number;
    username: string;
    languageCode: string;
    isPremium: boolean;
    message: string;

    type: EConversationType;
    status?: EIssueType;
};

export type TProfile = {
    email: string;
    username: string;
};

export type TPagination = {
    page: number;
    pageSize: number;
    total: number;
    totalPages: number;
};

export type TTask = {
    type: ETaskType;
    value: number;
    cardId?: string;
};

export type TLevel = {
    level: number;
    perHour: number;
    timeout: number;
    price: number;
    tasks: TTask[];
};

export type TCard = {
    id: string;
    category: ECardType;
    title?: TLocalization;
    subtitle?: TLocalization;
    description?: TLocalization;
    image: string;
    hidden: boolean;
    levels: TLevel[];
    expiredAt?: Date;
    deletedAt?: Date;
};

export type TLocalization = {
    uk: string;
    en: string;
    ru: string;
};
